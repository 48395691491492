
import { defineComponent } from 'vue';
import {Button, Table, Searchbar} from '@/ui/';
import { Workarea } from '@/model';
import { WorkareaController } from '@/controller/';
import store from "@/store";

export default defineComponent({
    name: "WorkareaList",
    components: { Button, Searchbar, Table },
    created(){
        this.handleGetWorkareas();
    },
    data(){
        return{
            store,
            workareasTableData:{
                thFields: [ {title:this.$t("label.number"), property: "number", type: "string"}, {title:this.$t("label.title"), property: "title", type: "string", clickAction: 'editWorkarea'}, {title:this.$t("label.department"), property: "department.title", type: "string", clickAction: 'openDepartment'}  ],
                tbActions: [ { name: "editWorkarea", icon: store.getters.svgIcons.pencil }, { name: "confirmDelete", icon: store.getters.svgIcons.remove } ]
            },
            workareas: [] as Array<Workarea>,
            searchInput: ""
        }
    },
    computed: {
        filteredWorkareas() {
            let filtered = [] as Array<any>;
            if (this.searchInput) {
                this.workareas.forEach((workarea : any) => {
                    JSON.stringify(workarea.title).toLowerCase().includes(this.searchInput.toLowerCase()) && filtered.push(workarea);
                    !filtered.includes(workarea) && workarea.description && JSON.stringify(workarea.description).toLowerCase().includes(this.searchInput.toLowerCase()) && filtered.push(workarea);
                })
            } else {
                filtered = this.workareas;
            }
            return filtered;
        }
    },
    methods:{
        async handleGetWorkareas() {
            const res = await WorkareaController.fetchWorkareas();
            if(!res.error){
                this.workareas = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        handleTableActionClicked(action : string, item:any) {
            switch(action) {
                /*case "openDetail":
                    this.handleOpenDetailView(item.uid);
                    break*/
                case "confirmDelete":
                    this.handleConfirmDelete(item);
                    break
                case "editWorkarea":
                    this.handleEdit(item.uid);
                    break
                case "openDepartment":
                    this.handleOpenDepartment(item);
                    break

            }
        },
        handleShowCreateForm() {
            this.$router.push("/administration/workarea/form/0");
        },
        handleEdit(uid:number) {
            this.$router.push("/administration/workarea/form/" + uid);
        },
        handleOpenDepartment(item: any) {
            this.$router.push("/administration/department/detail/" + item.department.uid)
        },
        handleConfirmDelete(item : any) {
            this.$confirm(this.$t("confirm.delete"), this.$t('confirm.deleteWorkareaText', { name: item.title }), {
                labelConfirm: this.$t("button.delete"),
                onConfirm: () => { this.handleDelete(item.uid) },
                //onCancel: () => { console.log("onCancel"); }
           });
        },
        async handleDelete(uid : number) {
            const res = await WorkareaController.deleteWorkarea(uid);
            if (!res.error) {
                this.workareas = this.workareas.filter(function(item : any) {
                   return item.uid != res.workarea.uid;
                });
                this.$notify(this.$t("success.workareaDeleted"), { position: "top" });
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        }
    }
})
